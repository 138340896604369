/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** extend sidea */

.ProductWishlistButton {
    display: inline-block;
    height: var(--wishlist-heart-size);
    width: var(--wishlist-heart-size);

    &-Button.Button {
        background-color: unset;
        border: none;
        height: var(--wishlist-heart-size);
        padding: 0;
        box-shadow: none;

        &:hover,
        &:focus {
            background-color: unset;
            height: var(--wishlist-heart-size);
            padding: 0;
        }

        span {
            display: none;
        }
    }
}
