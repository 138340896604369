/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --option-background-color: #{$white};
    --option-check-mark-background: #{$white};
    --option-size: 21px;
    --option-size-text: 68px;
    --option-text-color: #{$black}; // Move to root
    --option-border-color: #c1c1c1;

    @include mobile {
        --option-size: 27px;
    }
}

.ProductAttributeValue {
    --button-background: initial;
    --button-color: initial;
    --button-padding: 0;
    --option-is-selected: 0;

    
    &:hover {
        @include desktop {
            --option-text-color: #{$default-primary-base-color};
        }

        text-decoration: none;

        span {
            border: 2px solid $black;
            line-height: 27px;
        }
    }

    label, &-Label {
        position: unset;
    }

    &-Color,
    &-String,
    &-Image,
    &-Text {
        font-family: $font-muli;
        background-color: var(--option-background-color);
        border-color: var(--option-border-color);
        border-width: 1px;
        color: var(--option-text-color);
        display: inline-block;
        min-height: var(--option-size);
        width: var(--option-size);
        margin-inline-end: 12px;
        cursor: pointer;

        @include narrow-desktop {
            margin-inline-end: 8px;
        }

        @include tablet {
            margin-inline-end: 7px;
        }

        @include mobile {
            margin-block-end: 5px;
        }
    }

    &-String {
        padding: 0 10px;
        margin-block-end: 0;

        &_isSelected {
            --option-text-color: #{$default-neutral-base-color};
        }
    }

    &-Image {
        border-radius: 9px;
        object-fit: cover;
        padding: 0;
        position: relative;
        width: var(--option-size);
        cursor: pointer;
        margin-inline-end: 12px;
        min-height: var(--option-size);
    }

    &-Image-Overlay {
        --option-check-mark-background: var(--color-white);

        inset-block-start: 0;
        inset-inline-start: 0;
        margin: 0;
        position: absolute;
    }

    &-Color {
        box-shadow: inset 0 0 0 1px #979797;
        box-shadow: inset 0 0 0 1px var(--option-border-color);
        border: 1px solid #dfdfdf;
    }

    &-Color,
    &-Image-Overlay {
        border-radius: 50%;
        font-size: 0;
        width: var(--option-size);
        will-change: box-shadow, background-color;
        margin-inline-end: 13px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            opacity: var(--option-is-selected);
            transition: opacity 200ms;
            will-change: opacity, border-inline-start, border-inline-end;
        }

        &::before {
            height: var(--option-size);
            width: var(--option-size);
            border-radius: inherit;
            box-shadow: inset 0 0 0 2px $black;
            inset-inline-start: -1px;
            inset-block-start: -1px;
        }

        &::after {
            display: none;
            height: calc(var(--option-size) / 5);
            inset-inline-start: calc(var(--option-size) / 3.3);
            inset-block-start: calc(var(--option-size) / 3.3);
            width: calc(var(--option-size) / 2.5);
            transform: rotate(-45deg);

            [dir="rtl"] & {
                transform: scale(-1, 1) rotate(45deg);
            }

            border-inline-start: 2px solid var(--option-check-mark-background);
            border-block-end: 2px solid var(--option-check-mark-background);
        }

        &:hover {
            @include desktop {
                &::before,
                &::after {
                    --option-is-selected: 1;
                }
            }
        }
    }

    &-String,
    &-Text {
        border-style: solid;
        border-radius: 10px;
        line-height: 29px;
        min-width: var(--option-size-text);
        font-weight: 400;
        width: auto;
        text-align: center;
        border-width: 1px;
        max-height: 30px;
        font-size: 1.6rem;

        &_isSelected {
            --option-border-color: #{$default-neutral-base-color};

            color: $default-secondary-base-color;
            border-width: 2px;
            font-weight: 400;
            line-height: 27px;

            span {
                color: $default-secondary-base-color;
            }
        }

        span {
            font-size: 1.6rem;
        }
    }

    &-Text {
        display: flex;
        border: 0;
        align-items: baseline;
        margin: 0;

        label,
        .input-control {
            font-weight: 400;
            cursor: pointer;
        }

        label {
            &:first-of-type {
                padding-block-end: 0;
                order: 1;
                text-align: start;
                overflow: hidden;
                text-overflow: ellipsis;

                //display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                line-height: 24px;

                .input-control {
                    inset-inline-start: 0;
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                    min-height: 18px;
                    vertical-align: top;
                    border-color: $black;

                    @include desktop {
                        align-self: self-start;
                        margin-block-start: 4px;
                    }
                }
            }

            img {
                height: 32px;
                object-fit: cover;
                vertical-align: middle;
                width: 32px;

                @include mobile {
                    margin: 5px 0px;
                }
            }
        }

        &:hover,
        &:focus {
            label {
                @include desktop {
                    border-color: var(--primary-base-color);
                }
            }
        }

        @include desktop {
            padding-block-end: 6px;
            padding-inline: 0;
        }
    }

    &_isNotAvailable, &_isNotExist, &_isNotInStock {
        --option-border-color: #DEDEDE;
        --option-text-color: #{$default-neutral-dark-color};

        opacity: 1;
        cursor: default;

        &:hover {
            @include desktop {
                --option-border-color: #DEDEDE;
                --option-text-color: #{$default-neutral-dark-color};

                span {
                    border-width: 1px;
                }
            }
        }

        &::after {
            content: "";
            width: calc(1.414 * 47px);
            transform: rotate(-18deg);
            transform-origin: bottom left;
            border-top: 1px solid #DEDEDE;
            position: absolute;
            inset-block-end: 10px;
            inset-inline-start: 3px;
            box-sizing: border-box;
        }

        .ProductAttributeValue {
            &-Color {
                &::before {
                    content: '';
                    content: var(--icona-prodotto-non-disponibile);
                    position: absolute;
                    width: 0px;
                    height: 15px;
                    // transform-origin: 50% 50%;
                    inset-block-start: 2.3px;
                    inset-inline-start: 2.3px;
                    transform: rotate(0deg);
                    box-shadow: none;
                    border-radius: 0;
                    opacity: 1;
                    // background-color: var(--option-check-mark-background);
                }
            }

            &-Color,
            &-Image-Overlay {
                &:hover {
                    @include desktop {
                        &::before,
                        &::after {
                            --option-is-selected: 0;
                        }
                    }
                }
            }
        }
    }

    &-SubLabel {
        position: absolute;
        inset-inline-end: 0;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: 0.1rem;
        color: #7d7d7d;
        white-space: break-spaces;
        padding-inline-start: 4px;
    }
}

