/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Override Sidea */

:root {
    --product-compare-item-option-color: #adadad;
    --product-compare-item-divider-color: var(--expandable-content-divider-color);
}

.ProductCompareItem {
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    height: 100%;
    min-height: 100px;
    margin-block-end: 25px;

    @include mobile {
        padding: 0;
    }

    &-Details {
        display: flex;
        flex-direction: column;
        margin-block-end: 15px;
    }

    &-Figure {
        flex: 0 0 auto;
        width: 250px;
        height: 250px;
        margin-inline-end: 10px;

        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background-color: #ebeff2;
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            z-index: 10;
            opacity: .7;
            mix-blend-mode: multiply;
        }
    }

    &-ImageLink {
        height: 100%;

        .Image {
            height: 100%;
        }
    }

    &-ProductInfo {
        padding-block-start: 15px;

        @include mobile {
            padding-block-start: 10px;
        }
    }

    &-Brand {
        font-weight: 700;
        opacity: 1;
    }

    &-Actions {
        display: flex;
        align-items: center;
    }

    .ProductWishlistButton {
        margin-inline: 16px;
    }

    &-Title {
        flex: 1;
        display: block;
        width: 100%;
        padding-inline-end: 19px;
        margin: 0;
        font-size: 1.6rem;
        line-height: 1.6;
        color: $black;
        font-weight: 400;
        font-family: $font-base;
    }

    &-AddToCartBtnWrapper {
        width: 100%;
        display: block;
        margin: 0;

        @include mobile {
            width: auto;
        }
    }

    &-AddToCartBtn {
        width: 250px;

        @include mobile {
            width: 200px;
        }
    }

    &-CloseBtn {
        inset-inline-end: 10px;
        inset-block-start: 0;
        position: absolute;
        z-index: 12;
        margin-block-start: 0;

        @include mobile {
            inset-inline-end: 24px;
            inset-block-start: 8px;
        }

        svg {
            transform: scale(0.7);
        }
    }
}

.ProductCompareAttributeRow {
    &-Title {
        font-size: 1.8rem;
        font-family: $font-muli;

        @include mobile {
            font-size: 1.6rem;
        }
    }

    &-Value {
        font-size: 1.4rem;

        @include mobile {
            font-size: 1.4rem;
        }

        p {
            font-size: 1.4rem;
        }
    }

    &-Values {
        .ProductPrice {
            &-PriceValue {
                font-size: 2rem;
                font-family: $font-muli;
                font-weight: 600;

                @include mobile {
                    font-size: 1.6rem;
                }
            }
        }
    }
}
