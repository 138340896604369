/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.ProductAlerts {
    &-Wrapper {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &-InStock,
    &-PriceDrop {
        text-align: start;
        color: var(--primary-base-color);
        cursor: pointer;
        font-size: 14px;
        
        @include hoverable {
            &:hover {
                color: var(--primary-dark-color);
            }
        }

        [dir="rtl"] & {
            text-align: end;
        }
    }

    &-PriceDrop {
        font-size: 12px;
        margin-block-start: 5px;

        &Wrapper {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .Field-Wrapper {
                display: inline-block;
                width: 60%;
                vertical-align: bottom;

                &_type_checkbox {
                    width: 90%;
                }

                .Field {
                    margin-block-start: 15px;

                    &-CheckboxLabel {
                        font-size: 10px;
                        line-height: 1.2;

                        .input-control {
                            width: 26px;
                            height: 15px;
                            align-self: flex-start;
                        }
                    }
                }

                input[type="email"] {
                    width: 100%;
                    height: 50px;
                }
            }

            .Button {
                max-width: 120px;
                align-items: flex-start;
                margin-inline-start: 20px;
            }
        }
    }
}
