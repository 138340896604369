/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #E3E3E3;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --product-card-padding-inline: 15px;
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;

    @include mobile {
        --product-card-padding-inline: 8px;
    }
}

.ProductCard {
    flex-direction: column;
    height: fit-content;
    padding-inline-start: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;
    margin-block-end: 25px;
    margin-inline-end: -1px;
    padding-inline: var(--product-card-padding-inline);

    @include desktop-wide-1600 {
        height: unset;
    }

    @include mobile {
        padding-inline: 0 var(--product-card-padding-inline);
        margin-block-end: 25px;
    }

    &::before {
        content: none;
    }

    &:last-child {
        margin-block-end: 0;

        @include desktop-wide-1440 {
            margin-block-end: 0;
        }

        @include narrow-desktop {
            margin-block-end: 0;
        }

        @include tablet {
            margin-block-end: 0;
        }

        @include mobile {
            margin-block-end: 20px;
        }
    }

    .ProductConfigurableAttributes {
        &-Title {
            display: none;
        }
    }

    &-Attributes {
        padding: 0;
    }

    &-ConfigurationNotice {
        opacity: 0.3;
        font-weight: normal;
        margin-block-start: -10px;
        margin-block-end: 10px;
    }
    
    &-Content {
        background: var(--product-card-background);
        padding-block: 25px 0;
        padding-inline: 0;
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;

        @mixin padding-narrow {
            padding-block: 15px 0;
        }

        @include narrow-desktop {
            @include padding-narrow;
        }

        @include ultra-narrow-desktop {
            @include padding-narrow;
        }

        @include tablet {
            @include padding-narrow;
        }

        @include mobile {
            padding-block: 6px;
        }

        p {
            font-size: 2rem;

            @include narrow-desktop {
                font-size: 1.6rem;
            }

            @include ultra-narrow-desktop {
                font-size: 1.4rem;
            }

            @include tablet {
                font-size: 1.4rem;
            }

            @include mobile {
                font-size: 1.2rem;
            }
        }

        &_layout_list {
            justify-content: space-between;
            margin-inline-start: 20px;
            max-width: 325px;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;
                    visibility: hidden;

                    &_loaded {
                        visibility: visible;
                    }

                    button {
                        margin-inline-end: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-block-end: 0;
                    }

                    .ProductCompareButton-Button {
                        height: var(--compare-button-size);
                        width: var(--compare-button-size);
                    }
                }

                &-OutOfStock {
                    p {
                        margin-inline-end: 10px;
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                    margin-block-end: 16px;
                }

                &-WishListButton {
                    height: var(--wishlist-heart-size);
                    width: var(--wishlist-heart-size);
                }
            }
        }
    }

    &-ProductInfo {
        width: 100%;

        @include mobile {
            padding-inline-end: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-block-end: 0;
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        margin: 0 0 5px;
        font-size: 2rem;
        padding-block-end: 0;
        overflow: visible;
        text-overflow: unset;
        white-space: normal;
        font-weight: 400;

        @mixin size-narrow {
            font-size: 1.8rem;
        }

        @include narrow-desktop {
            @include size-narrow;
        }

        @include ultra-narrow-desktop {
            @include size-narrow;
        }

        @include tablet {
            @include size-narrow;
        }
       
        @include mobile {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1.4rem;
            margin: 0;
        }
    }

    &-Brand,
    &-Brand a,
    .Product-Brand,
    .Product-Brand a {
        font-size: 1.6rem;
        font-family: $font-muli;
        font-weight: 600;
        margin-block-end: 7px;
        opacity: 1;
        text-transform: none;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);

        @include narrow-desktop {
            font-size: 1.4rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.4rem;
            margin-block-end: 0;
        }

        @include tablet {
            font-size: 1.4rem;
            margin-block-end: 0;
        }

        @include mobile {
            font-size: 12px;
            margin-block-end: 3px;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-block-end: 100%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-LinkInnerWrapper {
        width: 100%;
        height: 100%;

        &:hover {
            @include desktop {
                box-shadow: none;
                z-index: 4;
            }
        }

        &_loaded:hover {
            @include desktop {
                .ProductCard-VisibleOnHover {
                    background: transparent;
                    width: inherit;
                    opacity: 1;
                    box-shadow: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    flex-grow: 1;
                    padding-block-start: 0;
                    padding-block-end: 0;
                    padding-inline: 0;
                    position: absolute;
                    inset-block-start: 25px;
                    inset-inline-end: 21px;
                    z-index: 99;
                }
            }
        }
    }

    &-FigureReview {
        border: 1px solid #fff;
        background: var(--product-card-background);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;

        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background-color: #ebeff2;
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            z-index: 10;
            opacity: .7;
            mix-blend-mode: multiply;
        }

        &:hover {
            @include desktop {
                border: 1px solid $black;
            }
        }
    }

    &-Reviews {
        --product-rating-size: 16px;
        --product-rating-background: #ffff;

        width: 100%;
        border-radius: 10px;
        letter-spacing: normal;

        @include mobile {
            --product-rating-size: 14px;

            width: 100%;
            border-radius: 0;
            height: 18px;
        }

        @include desktop {
            height: 25px;
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 0;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include desktop {
            margin-block-end: 27px;
        }

        @mixin margin-end-narrow {
            margin-block-end: 17px;
        }

        @include narrow-desktop {
            @include margin-end-narrow;
        }

        @include ultra-narrow-desktop {
            @include margin-end-narrow;
        }

        @include tablet {
            @include margin-end-narrow;
        }

        .ProductPrice-PriceValue {
            margin-block-start: 0px;
            line-height: 1;
    
            @include mobile {
                margin-block-start: 4px;
            }
        }

        .ProductPrice-Price {
            display: block;
            justify-content: space-between;
            flex-wrap: unset;
        }
    }

    &-Price {
        width: 100%;
        font-family: $font-muli;
        display: flex;
        
        @include desktop {
            font-size: 2rem;
        }

        @mixin price-size-narrow {
            font-size: 1.8rem;
        }

        @include narrow-desktop {
            @include price-size-narrow;
        }

        @include ultra-narrow-desktop {
            @include price-size-narrow;
        }

        @include tablet {
            @include price-size-narrow;
        }

        @include mobile {
            font-size: 14px;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    .ProductPrice-PriceBadge {
        font-size: 12px;
    }

    .ProductPrice-BundleTo {
        @include mobile {
            margin-block-start: 6px;
        }
    }

    &-Figure {
        flex-grow: 1;
        line-height: 0;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            color: initial;
            text-decoration: none;
        }
    }

    &-ProductActions {
        display: flex;
        margin-inline-start: 7px;

        .ProductWishlistButton {
            margin-inline-end: 10px;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-Footer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-block-start: 0;

        > * {
            margin-inline-end: 5px;
        }

        .ProductWishlistButton {
            svg {
                stroke: $default-neutral-light-color;
                width: 21px;
                height: 19px;

                @include desktop-wide-1440 {
                    width: 20px;
                    height: 17px;
                }

                @include ultra-narrow-desktop {
                    width: 20px;
                    height: 16px;
                }

                @include tablet {
                    width: 18px;
                    height: 15px;
                }

                @include mobile {
                    width: 18px;
                    height: 15px;
                    opacity: 0.3;
                }
            }

            &:hover {
                svg {
                    stroke: $default-primary-base-color ;
                }
            }
        }
    }

    &-OutOfStock {
        align-items: center;
        display: flex;
        height: 48px;

        p {
            margin: 0;
        }
    }

    &-VisibleOnHover {
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        padding-block-start: 0;
        padding-block-end: 0;
        padding-inline: 0;
        opacity: 1;
        position: absolute;
        inset-block-start: 25px;
        inset-inline-end: 21px;
        display: flex;

        @include desktop-wide-1440 {
            inset-block-start: 20px;
            inset-inline-end: 18px;
        }

        @include ultra-narrow-desktop {
            inset-block-start: 16px;
            inset-inline-end: 14px;
        }

        @include tablet {
            inset-block-start: 11px;
            inset-inline-end: 11px;
        }

        @include mobile {
            display: flex;
            inset-block-start: 7px;
            inset-inline-end: 5px;
            padding-inline-end: 0;
            opacity: 1;
        }
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }

    .MoreColors {
        &-Label {
            display: flex;
            width: fit-content;
            gap: 14px;

            @include mobile {
                gap: 10px;
            }

            span {
                --circle-size: 22px;

                @include narrow-desktop {
                    --circle-size: 20px;
                }

                @include ultra-narrow-desktop {
                    --circle-size: 20px;
                }

                @include tablet {
                    --circle-size: 20px;
                }

                display: block;
                width: var(--circle-size);
                height: var(--circle-size);
                border-radius: 50%;
                border: 1px solid transparent;

                @include desktop {
                    cursor: pointer;
                }

                @include mobile {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}
