/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

.ProductConfigurableAttributes {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;

    @include desktop {
        padding-block-start: 24px;
        padding-block-end: 45px;
    }

    &-SwatchList,
    &-DropDownList {
        text-align: center;
        margin-block-start: 2px;
        margin-block-end: 12px;
    }

    &-Title {
        font-weight: 700;
        margin-block: 24px 12px;

        @include mobile {
            font-size: 16px;
        }
        
        &_isUnselected {
            animation: var(--shake-animation);
        }
    }

    &-SelectedOptionLabel {
        font-weight: normal;
        color: $black;
        opacity: 1;
        padding-inline-start: 10px;
    }

    &-SwatchList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        row-gap: 6px;
        padding-inline-start: 3px;
        padding-block-start: 3px;
        
        @include mobile {
            margin-block-start: 8px;
            padding-inline-start: 0;
        }

        &_isUnselected {
            animation: var(--shake-animation);
        }

        .Button-Text {
            display: none;
            font-family: $font-base;
            font-size: 12px;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 500;
            color: $default-secondary-dark-color;

            &:hover {
                color: $default-primary-dark-color;
            }

            @include desktop {
                inset-block-start: 10px;
            }

            @include mobile {
                inset-inline-end: 0;
            }
        }
    }

    &-Placeholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        height: var(--option-size);
        width: var(--option-size);
        margin-block-end: var(--option-margin-block);
        margin-block-start: var(--option-margin-block);
        margin-inline-end: var(--option-margin-inline);
        margin-inline-start: var(--option-margin-inline);
    }

    &-Expandable:first-of-type {
        border-block-start: none;
    }

    &-Expandable.ExpandableContent:last-of-type {
        border-block-end: none;
    }
}

.ProductConfigurableAttributeDropdown {
    width: 100%;
    
    select {
        border-color: var(--option-border-color);
        margin-block-start: 5px;
        height: 50px;
        padding: 10px 22px;
    }

    .FieldSelect-Options_isExpanded {
        border-color: var(--option-border-color);
    }
}
