/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Style component Sidea - selezione negozio Collect e modal info spedizione */

.ProductActions {
    &-ShippingMethods {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-block-start: 25px;

        @include ultra-narrow-desktop {
            gap: 15px;
            margin-block-start: 15px;
        }

        @include tablet {
            gap: 15px;
            margin-block-start: 15px;
        }

        @include mobile {
            margin-block-start: 27px;
            margin-block-end: 16px;
            display: flex;
            flex-direction: column;
            row-gap: 35px;
        }

        p {
            font-family: $font-muli;
            margin-block-end: 0px;
            font-size: 1.8rem;
            font-weight: 700;
        }

        a, button {
            font-family: $font-base;
            text-decoration: underline;
            font-size: 12px;
            cursor: pointer;
            color: $black;
            font-weight: 400;

            @include mobile {
                font-family: $font-muli;
                font-size: 1.5rem;
            }
            
            &:hover {
                color: var(--link-hover);
            }
        }

        svg {
            display: inline-block;
            vertical-align: middle;
        }

        .BoxCollect,
        .BoxShipping {
            &-Wrapper {
                display: flex;
                gap: 13px;

                span {
                    font-size: 12px;
                    font-weight: 700;
                    color: $default-primary-base-color;
                }
        
                .StoreSelection {
                    strong {
                        display: block;
                    }
                }
            }
        }
    }
}
