/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --compare-button-size: 50px;
}

.ProductCompareButton {
    display: inline-block;
    height: var(--compare-button-size);
    width: auto;
    margin-block-start: 0;
    margin-inline-start: 15px;

    .CompareIcon {
        vertical-align: middle;
        margin-inline-end: 5px;

        &.CompareIcon_isActive {
            fill: $black;
        }
    }

    &-Button {
        font-size: 1.2rem;
        text-decoration: underline;
        cursor: pointer;

        @include narrow-desktop {
            display: flex;
            text-align: start;
        }

        @include tablet {
            display: flex;
            text-align: start;
        }

        &:hover {
            color: $default-primary-base-color;

            .CompareIcon {
                fill: $default-primary-base-color;
        
                &.CompareIcon_isActive {
                    fill: $default-primary-base-color;
                }
            }
        }

        &.Button {
            background-color: unset;
            border: none;
            height: var(--compare-button-size);
            padding: 0;

            &:hover,
            &:focus {
                background-color: unset;
                height: var(--compare-button-size);
                padding: 0;
            }
        }
    }
}
