/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --product-tabs-width: 54.5%;

    @include desktop-wide-1440 {
        --product-tabs-width: 49%;
    }

    @include ultra-narrow-desktop {
        --product-tabs-width: 100%;
    }

    @include tablet {
        --product-tabs-width: 100%;
    }
}

.ProductTabs {
    display: flex;
    border-block-end: none;

    @include desktop {
        width: var(--product-tabs-width);
        display: flex;
    }

    &-Wrapper {
        @include desktop {
            margin-inline-start: calc(var(--product-page-wrapper-margin) + var(--max-gallery-thumbnail-height) + 10px);
            margin-inline-end: var(--product-page-wrapper-margin);
            margin-block-end: 110px;
        }

        @include desktop-wide-1600 {
            margin-block-end: 70px;
        }

        @include ultra-narrow-desktop {
            margin-block-end: 0;
            margin-inline-start: var(--product-page-wrapper-margin);
            margin-inline-end: var(--product-page-wrapper-margin);
        }

        @include tablet {
            margin-block-end: 0;
            margin-inline-start: var(--product-page-wrapper-margin);
            margin-inline-end: var(--product-page-wrapper-margin);
        }

        @include mobile {
            padding: 0;
            margin-block-start: 20px;
            border-top: 1px solid #D4D4D4;
        }

        .ExpandableContent-Heading {
            text-transform: none;
            display: block;
            font-size: 2.4rem;
            font-weight: 500;

            @include mobile {
                font-size: 2.1rem;
                font-weight: 700;
                text-transform: none;
            }
        }

        .ExpandableContent {
            border-block-end: 0;
            border-block-start: 0;
            
            &-Content {
                @include desktop {
                    margin-block-start: 0;
                }
            }

            &-Button {
                @include mobile {
                    border-bottom: 1px solid #D4D4D4;

                    .minusIcon {
                        font-size: 30px;
                        line-height: 0;
                        margin-inline-end: 7px;
                    }
                }

                &_isContentExpanded {
                    @include mobile {
                        border-bottom: none;
                    }
                }
            }
        }

        .ProductAttributes-Content {
            @include desktop {
                width: 54.5%;
            }
        
            @include desktop-wide-1600 {
                width: 58.5%;
            }
        }
    }

    .ProductTabs {
        justify-content: space-between;

        &-Panels {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            max-width: 750px;

            @include mobile {
                flex-direction: column;
            }

            p {
                font-size: 1.4rem;

                @include mobile {
                    font-size: 1.2rem;
                    line-height: 20px;
                }
            }

            a {
                text-decoration: underline;
            }
            
            .TabHeader {
                border-block-end: 1px solid #dedede;
                padding-block-end: 15px;
                position: relative;
                margin-block-end: 36px;

                @include mobile {
                    padding-block-end: 10px;
                    font-size: 2.6rem;
                    margin-block-end: 20px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block-end: -1px;
                    z-index: 1;
                    display: block;
                    width: 221px;
                    height: 1px;
                    border-top: 1px solid $black;
                }
            }

            .ProductInformation {
                width: 100%;

                @include mobile {
                    width: 100%;
                }

                &.ShopAdvantages {
                    width: 100%;

                    @include mobile {
                        width: 100%;
                        margin-block-start: 25px;
                    }

                    .pagebuilder-column-group {
                        @include ultra-narrow-desktop {
                            column-gap: 15px;
                        }

                        @include tablet {
                            column-gap: 15px;
                        }
                    }
                }
            }

            .ProductAttributes-ExpandableContentContent {
                columns: 1;
                grid-column-gap: 0;
                column-gap: 0;
            }

            .ProductAttributes-Content,
            .CmsBlock-Wrapper {
                width: 100%;
            }

            .CmsBlock-Wrapper {
                padding-block-start: 0;

                @include mobile {
                    padding-block-start: 12px;
                }

                h3 {
                    margin-block-end: 3px;
                    margin-block-start: 0;

                    @include mobile {
                        font-size: 12px;
                        margin-inline-start: 20px;
                    }
                }

                p {
                    margin-block-end: 9px;

                    @include mobile {
                        font-size: 12px;
                        margin-inline-start: 20px;
                    }
                }

                a {
                    @include mobile {
                        font-size: 12px;
                    }    
                }

                img.Image-WidthFull.Image-HeightFull {
                    width: auto;
                    height: auto;
                }
            }
        }
    }

    .ProductTab {
        &-Item {
            width: 49%;
            border-bottom: 1px solid #DEDEDE;

            &_isActive {
                border-bottom: 1px solid $black;

                .ProductTab-Button {
                    color: $black;
                }
            }
        }

        &-Button {
            font-family: $font-muli;
            font-size: 2.4rem;
            font-weight: bold;
            color: $default-neutral-base-color;
            line-height: 1;
            padding-block: 20px;
            padding-inline: 0;
            margin: 0;
            cursor: pointer;
        }
    }
}
