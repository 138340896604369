/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Override Sidea */

:root {
    --carousel-scroll-item-height: 38px;
    --carousel-scroll-margin-top: 28px;
    --max-gallery-thumbnail-height: 142px;
    --gallery-grid-gap: 18px;

    @include desktop-wide-1440 {
        --max-gallery-thumbnail-height: 130px;
    }

    @include narrow-desktop {
        --max-gallery-thumbnail-height: 117px;
    }

    @include ultra-narrow-desktop {
        --max-gallery-thumbnail-height: 117px;
        --gallery-grid-gap: 10px;
    }

    @include tablet {
        --max-gallery-thumbnail-height: 117px;
        --gallery-grid-gap: 10px;
    }

    @include narrow-tablet {
        --max-gallery-thumbnail-height: 96px;
        --gallery-grid-gap: 10px;
    }
}

.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallery {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 59%;
    margin-inline: 0;

    @include desktop-wide-1440 {
        width: 55%;
    }

    @include mobile {
        margin-inline: -20px;
        width: calc(100% + 40px);
        height: fit-content;
        margin-block-end: 0;
        padding: 0;
        flex-direction: column-reverse;
        margin-block-start: 0;
    }

    &-Additional {
        width: 19%;
        max-width: calc(var(--max-gallery-thumbnail-height) + 2px);
        margin-inline-end: 10px;
        flex: 0 0 auto;
        min-height: calc(var(--carousel-scroll-item-height) + var(--carousel-scroll-margin-top));

        @include mobile {
            display: block;
            width: 100%;
            max-width: unset;
            margin-inline-end: 0;
            margin-block-start: 0;
        }

        .CarouselScroll {
            margin-block-start: 0;

            @include desktop {
                width: 100%;
                align-items: flex-start;
            }

            @include mobile {
                width: 100%;
            }

            &-ContentWrapper {
                flex-direction: column;
                width: 100%;

                @include mobile {
                    flex-direction: row;
                    overflow-x: scroll;
                }
            }

            .CarouselScrollArrow {
                @include mobile {
                    display: none;
                }
            }

            &-Content {
                flex-direction: column;
                grid-row-gap: var(--gallery-grid-gap);

                @include mobile {
                    flex-direction: row;
                }

                .CarouselScrollItem {
                    width: 100%;

                    + .CarouselScrollItem {
                        margin-inline-start: 0;
                    }

                    .ProductGalleryThumbnailImage {
                        padding: 0;
                        background-color: #ebeff2;

                        @include mobile {
                            display: block;
                            height: 100%;
                            overflow: hidden;
                        }

                        &.Image {
                            height: var(--max-gallery-thumbnail-height);

                            @include mobile {
                                height: 100%;
                            }

                            .Image-Image {
                                width: auto;
                                height: 100%;
                                margin: 0 auto;
                                mix-blend-mode: multiply;

                                @include mobile {
                                    width: 100%;
                                    height: auto;
                                    position: relative;
                                    display: flex;
                                }
                            }
                        }

                        img {
                            position: relative;
                        }
                    }
                }
            }

            .CarouselScrollItem {
                border-color: $default-neutral-light-color;
                filter: opacity(1);

                &_isActive,
                &:hover {
                    border-color: $default-neutral-base-color;
                }

                @include mobile {
                    display: block;
                    height: auto;
                }
            }
        }

        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;

            .CarouselScrollItem {
                filter: opacity(0.75);

                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }
        }

        &_isWithEmptySwitcher {
            height: 66px;
        }
    }

    &-SliderWrapper {
        flex: 1;
        height: 100%;
        max-height: calc((var(--max-gallery-thumbnail-height) * 4) + (var(--gallery-grid-gap) * 3));
        width: 81%;

        @include mobile {
            height: auto;
            max-height: unset;
            width: 100%;
            padding-block-end: 0;
        }

        .Slider-Arrow {
            display: none;

            &_isPrev {
                inset-inline-start: 0;
            }
    
            &_isNext {
                inset-inline-end: 0;
            }
        }

        &:hover {
            .Slider-Arrow {
                display: block;
            }
        }
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);

        @include mobile {
            height: var(--slider-height);
            max-height: 330px;
        }

        &_isImageZoomPopupActive {
            cursor: pointer;
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }

        .Slider-Crumbs {
            @include mobile {
                position: absolute;
                inset-block-start: 0;
                inset-inline-end: 11px;
                display: flex;
                flex-direction: column;
                width: 10px;
                height: 100%;
                z-index: 20;
    
                .Slider-Crumb {
                    width: 4px;
                    height: 19px;
                    background-color: $white;
                    border-radius: 0;
        
                    &_isActive {
                        width: 4px;
                        height: 50px;
                        background-color: $default-primary-dark-color;
                    }
                }
            }
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;

        img {
            height: 100%;

            @include mobile {
                position: relative;
            }
        }

        &::after {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background-color: #ebeff2;
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            z-index: 10;
            opacity: .7;
            mix-blend-mode: multiply;
        }
    }
}
