/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Override Sidea */

:root {
    --product-compare-area-width: var(--content-wrapper-width);
    --product-compare-sidebar-width: 330px;
    --product-compare-sidebar-padding: 30px;
    --product-compare-item-count: 3.2;
    --product-compare-item-gap: 15px;
    --prouduct-compare-additional-gap: 18px;
    --product-compare-column-width: calc(300px + var(--product-compare-item-gap) * 2);

    
    @include narrow-desktop {
        --product-compare-sidebar-width: 280px;
    }

    @include tablet {
        --product-compare-sidebar-width: 260px;
    }

    @include mobile {
        --product-compare-sidebar-width: 280px;
        --product-compare-column-width: 280px;
    }
}

.ProductCompare {
    overflow: auto;
    padding-block-end: 15px;
    margin-block-end: 30px;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    @include mobile {
        padding: 0;
        min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
        display: flex;
        flex-direction: column;
    }

    .ProductCardRow {
        min-width: fit-content;

        @include mobile {
            padding-block-start: 45px;
        }
    }

    &-Row {
        display: flex;
        align-items: stretch;
        margin-block-start: 20px;

        @include mobile {
            flex: 0 0 auto;
            margin-block-start: 0;
        }
    }

    &-FirstColumn {
        flex: 0 0 auto;
        width: var(--product-compare-sidebar-width);
        position: sticky;
        inset-inline-start: 0;
        background-color: $white;
        z-index: 10;
    }

    &-Item {
        flex: 1;
        width: var(--product-compare-column-width);
        max-width: var(--product-compare-column-width);
        padding-inline-end: var(--product-compare-item-gap);

        @include mobile {
            padding: 0;
        }
    }

    &-AttributeTable {
        min-width: fit-content;
        border-bottom: 1px solid #DEDEDE;
    }

    &-Empty {
        padding-block-start: 16px;
        margin-inline: 16px;
    }

    .ClearButton {
        padding-block-end: 35px;
        inset-inline-start: 0;
        max-width: calc(var(--product-compare-sidebar-width) + var(--product-compare-item-gap));

        @include mobile {
            position: absolute;
            inset-inline-start: 0;
            inset-block-start: 0;
            z-index: 5;
            padding-block-end: 0;
        }

        .Button {
            width: fit-content;
            color: #767676 !important;
            margin-inline-end: var(--product-compare-sidebar-padding);
            box-shadow: none;
            border: none;
            padding-inline: 0;

            @include mobile {
                width: 100%;
                max-width: 210px;
                justify-content: flex-start;
                font-size: 1.4rem;
            }

            svg {
                margin-inline-end: 7px;
                transform: scale(0.8);

                @include mobile {
                    margin-inline-end: 5px;
                }
            }

            &:hover {
                background-color: #fff;
                color: $default-primary-dark-color !important;

                svg {
                    fill: $default-primary-dark-color;
                }
            }
        }
    }

    .ProductPriceRow {
        min-width: fit-content;
        width: 100%;
        border-block-start: 1px solid var(--secondary-base-color);
        padding-block: 24px;
    }

    &-RowPrices {
        min-width: fit-content;
        width: 100%;
    }

    .PriceLabel {
        font-weight: 700;
        font-size: 14px;
        margin: 0;
    }

    .ProductPrice {
        flex: 0 0 auto;
        padding: 0 var(--product-compare-item-gap);
        width: calc(var(--product-compare-column-width));

        &:last-child {
            padding-inline-end: 0;
        }

        @include mobile {
            padding-inline: 0;
            margin-block-start: 5px;
        }
    }

    &-Scroller {
        inset-inline-start: 0;
        position: sticky;

        &Inner {
            margin: auto;
        }

        &Scroll {
            height: 20px;
            margin-inline-start: var(--product-compare-sidebar-width);
            overflow-x: auto;

            @include mobile {
                margin-inline-start: 0
            }
        }

        &Content {
            height: 1px;
        }
    }

    .AccountReturnButton {
        margin: 0 auto;
        display: block;
        text-align: center;
    }
}
