/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.ProductCompareAttributeRow {
    display: flex;
    align-items: center;
    padding-block: 20px;
    font-size: 1.4rem;
    border-block-start: 1px solid #DEDEDE;

    @include mobile {
        display: block;
        margin: 0;
        padding-block: 10px;
        padding-inline-end: calc((100vw - 18px) - var(--product-compare-column-width));
    }

    &-Title {
        flex: 0 0 auto;
        width: var(--product-compare-sidebar-width);
        position: sticky;
        inset-inline-start: 0;
        font-weight: 700;
        font-size: 1.8rem;
        text-align: end;
        padding-inline-end: 30px;

        @include desktop {
            background-color: $white;
            z-index: 10;
        }

        @include mobile {
            font-size: 1.6rem;
            padding-inline: 0;
            padding-block-end: 0;
            white-space: nowrap;
            text-align: start;
            padding-inline-end: 0;
        }
    }

    &-Values {
        display: flex;
        align-items: center;
    }

    // make attribute value cell content narrower than table header

    &-Value {
        @include default-list;

        flex: 0 0 auto;
        padding-block: 0;
        padding-inline: var(--product-compare-item-gap) calc(var(--product-compare-item-gap) + var(--prouduct-compare-additional-gap));
        width: var(--product-compare-column-width);
        text-align: justify;

        @include mobile {
            padding-inline: 0 !important;
        }

        .Image-Content {
            position: inherit;
        }

        img {
            height: auto;
        }

        &:last-child {
            padding-inline-end: 0;
        }
    }

    &-OutOfStock {
        /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
        @extend .ProductCompareAttributeRow-Value;

        @include mobile {
            padding-block-start: 10px;
        }
    }

    &.Descrizione, &.Description {
        align-items: flex-start;

        .ProductCompareAttributeRow-Values {
            @include mobile {
                align-items: flex-start;
                margin-block-start: 5px;
            }
        }

        .ProductCompareAttributeRow-Value {
            padding-block-start: 4px;

            @include mobile {
                padding-inline-end: 40px !important;
            }
        }
    }

    &.Marchio, &.Manufacturer, &.Brand {
        display: none;
    }
}
