/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


/** Override Sidea */

.ProductInformation {
    font-size: 14px;
    line-height: 20px;

    @include desktop {
        width: var(--product-tabs-width);
        padding-block: 60px;
    }

    &-Wrapper {
        padding: 0;

        @include desktop {
            padding-block: 0;
        }
    }

    &-ExpandableContentContent {
        line-height: 20px;
        text-align: justify;

        + div {
            margin-block-end: 12px;
        }

        &_isContentExpanded {
            margin-block-end: 16px;
        }
    }

    &-ExpandableContentHeading {
        @include desktop {
            display: none !important;
        }
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Content {
        @include desktop {
            margin-block-end: 12px;
        }
    }

    &-Description {
        width: 100%;

        @include description-tags {
            font-size: 14px;
            line-height: 24px;
        }

        li {
            &::before {
                position: relative;
                margin-inline-start: 10px;
                margin-inline-end: -25px;
                inset-block-start: -1px;
            }
        }

        @include default-list;

        @include mobile {
            line-height: 20px;
        }
    }
}
