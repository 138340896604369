/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.ProductActions {
    line-height: 16px;

    @include mobile {
        position: unset;
        margin-block-start: 12px;
    }

    .FieldSelect-Option_isDisabled {
        pointer-events: unset; 
    }

    &-Attributes {
        padding: 0;
    }

    .Label {
        inset-block-start: 20px;
        inset-inline-start: calc(((var(--product-page-wrapper-gap) + var(--product-labels-width)) + 20px) * -1);

        @include mobile {
            inset-inline-start: -5px;
            inset-block-start: 12px;
            font-size: 2.5rem;
            width: 84px;
            height: 88px;

            span {
                font-size: 2.5rem;
            }
        }

        &.Sale {
            span {
                @include mobile {
                    font-size: 3.3rem;
                }
            }
        }

        &.New.small {
            inset-block-start: calc(var(--product-labels-width) + 30px);
            font-size: 3rem;

            @include mobile {
                font-size: 2.5rem;
            }
        }
    }

    .Product-Brand {
        border-bottom: 1px solid #DEDEDE;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-block-end: 15px;

        @include ultra-narrow-desktop {
            justify-content: flex-start;
        }

        @include tablet {
            justify-content: flex-start;
            padding-block-end: 12px;
        }
        
        @include mobile {
            height: auto;
            padding-block-end: 3px;
            font-size: 1.4rem;
        }
    }

    .Product-Brand,
    .LinkBrand {
        font-family: $font-muli;
        font-weight: 600;
        opacity: 1;
        font-size: 2.1rem;
        text-transform: none;
        line-height: 1;
        margin-block-start: 0;
        margin-block-end: 0;

        @include tablet {
            font-size: 2rem;
        }

        @include mobile {
            font-size: 1.4rem;
            margin-block-end: 7px;
        }
    }

    &::before {
        content: var(--trustpilot-logo);
        position: absolute;
        inset-inline-end: 0;
        display: block;
        width: 82px;

        @include mobile {
            transform: scale(0.9);
            margin-block-start: -4px;
            width: 74px;
        }
    }

    &-Title {
        font-size: 3rem;
        font-weight: 700;
        margin-block-start: 20px;
        margin-block-end: 6px;
        width: 100%;
    
        @include narrow-desktop {
            font-size: 2.6rem;
        }

        @include ultra-narrow-desktop {
            margin-block-start: 15px;
        }

        @include tablet {
            margin-block-start: 15px;
            font-size: 2.4rem;
        }

        @include mobile {
            font-size: 2.4rem;
        }

        @include mobile {
            font-size: 2.1rem;
            margin-block-start: 8px;
            width: calc(100% - 74px);
        }
    }

    &-ShortDescription {
        @include description-tags {
            line-height: 1.5;

            p {
                font-size: 1.4rem;
                font-weight: 500;
                font-style: italic;
                margin-block-end: 0;
            }
        }

        color: $black;
        font-size: 1.4rem;
        font-weight: 500;
        font-style: italic;
        margin-block-start: 10px;
        margin-block-end: 0;
        line-height: 1.5;

        a {
            text-decoration: underline;
        }

        div[itemprop="description"] li::before {
            position: relative;
            margin-inline-end: 10px;
            inset-block-start: -1px;
        }
    }

    &-ProductNotExist {
        background-color: #e4e4e4;
        padding: 20px;
        font-weight: 600;
        font-size: 1.4rem;
        margin-block-start: 20px;
    }

    &-Stock {
        font-size: 1.2rem;
        font-weight: 500;
        color: $default-neutral-base-color;
        order: 0;
        margin-block-start: 7px;

        @include desktop {
            margin-inline-end: 24px;
        }

        @include mobile {
            font-style: normal;
            font-size: 12px;
            line-height: 1.1;
            margin-block-start: 10px;
            color: $default-neutral-base-color;
        }
    }

    &-Sku {
        font-size: 1.4rem;
        order: 1;
        color: $black;

        @include mobile {
            font-weight: normal;
            font-size: 12px;
            line-height: 1.1;
            margin-block-start: 0;
            margin-inline-start: 0;
            text-align: start;
        }

        & + .ProductActions-Sku {
            margin-inline-start: 1ex;
        }
    }

    &-Review {
        order: 2;
        font-weight: 700;
        cursor: pointer;
        inset-block-end: -2px;
        font-size: 14px;

        @include mobile {
            inset-block-end: 0;
        }

        &Text {
            color: $default-secondary-dark-color;

            &:hover {
                color: var(--primary-base-color);
            }

            &_isNotSafariOrIos {
                @include mobile {
                    vertical-align: -webkit-baseline-middle;
                }
            }
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                @include mobile {
                    flex: 1;
                    align-items: flex-start;
                    flex-direction: row;
                }

                .TextPlaceholder {
                    line-height: 30px;
                }
            }

            &_alerts {
                display: inline-flex;
                align-content: flex-start;
                width: 100%;
                justify-content: space-between;

                .ProductActions-AddToCartWrapper {
                    &.outOfStock {
                        margin-block-start: 0;
                    }
                }
                
                .ProductAlerts-Wrapper {
                    width: 84%;

                    @include mobile {
                        width: 100%;
                    }

                    &.Guest {
                        & + div.outOfStock {
                            margin-block-start: 73px;
                        }
                    }
                }

                form {
                    input {
                        width: calc(100% + 55px);
                        max-width: 445px;
                        height: 50px;
                    }

                    label.Field-CheckboxLabel {
                        font-size: 10px;
                        line-height: 1.2;

                        &:hover {
                            color: $black;
                        }

                        .input-control {
                            width: 25px;
                            height: 15px;
                            align-self: flex-start;

                            @include ultra-narrow-desktop {
                                width: 35px;
                            }

                            @include tablet {
                                width: 35px;
                            }
                        }
                    }
                }

                .Button {
                    margin-block-start: 25px;
                    max-width: 380px;

                    @include ultra-narrow-desktop {
                        font-size: 1.8rem;
                    }

                    @include tablet {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }

    &-SizeGuide {
        font-family: $font-base;
        font-size: 12px;
        position: absolute;
        inset-block-end: 18px;
        inset-inline-start: 203px;

        .SizeGuide-Link {
            font-family: $font-base;
            font-size: 12px;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: $default-primary-base-color;
            }
        }
    }

    &-Schema {
        min-width: 150px;

        @include mobile {
            min-width: 100%;
            margin-block-end: 20px;
            margin-block-start: 10px;
        }
    }

    &-SchemaUrl {
        display: none;
    }

    &-PriceWrapper {
        font-family: $font-muli;
        font-size: 2.1rem;

        @include desktop {
            margin-block-start: 10px;
        }

        @include mobile {
            .ProductPrice {
                font-size: 2.1rem;
            }
        }
    }

    &-ActionButtons {
        display: flex;
    }

    &-ActionsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 12px;

        &:nth-child(2) {
            @include mobile {
                align-items: start;
            }
        }

        &:nth-child(3) {
            @include mobile {
                margin-block-end: 22px;
            }
        }

        @include desktop {
            margin-block: 18px;
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }
    }

    .ProductConfigurableAttributes {
        &-Title {
            font-size: 1.4rem;
            font-family: $font-muli;
            margin-block-end: 1px;
            margin-block-start: 4px;

            @include mobile {
                margin-block-start: 5px;
                margin-block-end: 5px;
            }
        }

        div:first-child {
            .ProductAttributeValue_isNotAvailable::after, .ProductAttributeValue_isNotExist::after, .ProductAttributeValue_isNotInStock::after {
                display: none;
            }
        }
    }

    &-AddToCartFixed {
        @include mobile {
            padding: 15px 18px 8px;
            inset-block-end: 45px;
            inset-inline-start: 0;
            display: flex;
            position: fixed;
            width: 100%;
            z-index: 85;
            background-color: $white;
            transition: transform 200ms cubic-bezier(0.47, 0, 0.745, 0.715);
        }

        .ProductActions-AddToCart {
            @include mobile {
                max-width: 100% !important;
            }
        }
    }

    &-AddToCartWrapper {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        margin-block-start: 25px;

        .Field-Wrapper_type_numberWithControls {
            width: 100%;
        }

        .ProductActions-AddToCart {
            margin-inline-end: 9px;
            line-height: 10px;
            max-width: 330px;

            @include desktop-wide-1600 {
                max-width: 300px;
            }

            @include ultra-narrow-desktop {
                max-width: 265px;
            }

            @include tablet {
                max-width: 265px;
            }

            @include mobile {
                margin-inline-end: 0;
                font-size: 1.6rem;
                max-width: calc(100% - 57px);
            }
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            margin-inline-end: 0;
            margin-inline-start: 0;
            height: auto;
            width: auto;

            .Button {
                border: 1px solid #C1C1C1;
                height: 50px;
                width: 50px;
                text-align: center;
                line-height: 50px;

                .HeartIcon {
                    transform: scale(0.8);
                }

                &:hover {
                    border-color: var(--primary-base-color);

                    .HeartIcon {
                        stroke: none;
                        fill: var(--primary-base-color);
                    }
                }

                span {
                    @include mobile {
                        display: none;
                        margin-inline-start: 10px;
                        text-decoration: underline;
                        font-weight: 500;
                    }
                }
            }
        }

        .ProductCompareButton {
            display: flex;
            align-content: center;
            margin-inline-start: 15px;
            height: 50px;

            @include desktop {
                position: absolute;
                inset-inline-start: 400px;
                inset-block-end: 0;
                margin-inline-start: 0;
                white-space: nowrap;
                align-items: center;
            }

            @include desktop-wide-1600 {
                inset-inline-start: 370px;
            }

            @include narrow-desktop {
                white-space: normal;
            }

            @include ultra-narrow-desktop {
                position: relative;
                inset-inline-start: 0;
            }

            @include tablet {
                position: relative;
                inset-inline-start: 0;
            }
        }

        &.outOfStock {
            @include desktop {
                display: inline-flex;
                width: 16%;
                margin-block-start: 99px;
            }

            .ProductCompareButton {
                display: none;
            }
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }

        .Field-ErrorMessages {
            position: absolute;
        }
    }

    &-Qty {
        display: flex;
        justify-content: space-between;

        &.Field {
            margin-block: 0;
            border: 1px solid #C1C1C1;
            width: 100px;
            height: 50px;
            margin-block-end: 24px;

            @include desktop {
                margin-inline-end: 24px;
            }

            @include ultra-narrow-desktop {
                margin-block-end: 20px; 
            }

            @include tablet {
                margin-block-end: 20px; 
            }

            @include mobile {
                margin-inline-end: 3px;
                margin-block-end: 15px;

                input {
                    min-width: 36px;
                }
            }
        }

        button {
            &:not([disabled]) {
                cursor: pointer;
            }

            &:disabled {
                cursor: default;
            }

            &:active {
                border-color: var(--primary-dark-color);
            }

            @include hoverable {
                &:hover {
                    border-color: var(--primary-dark-color);
                }
            }
        }
    }

    &-AttributesWrapper {
        margin-block-start: 22px;

        @include mobile {
            margin-block-end: 25px;
        }

        &.animate {
            animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
        }

        .FieldSelect {
            &-Clickable,
            &_isExpanded {
                width: 185px;
            }

            .ChevronIcon {
                inset-block-start: 24px;
            }
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;
    }

    &-Reviews {
        margin-block-end: 10px;
        display: flex;

        @include mobile {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            margin-inline-end: 24px;
        }
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }

    @include mobile {
        &.TopMobile {
            margin-block-start: 20px;
        }
    }
}
