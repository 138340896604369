/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    width: 100%;

    @include mobile {
        margin-block-start: 20px;
    }

    @include desktop {
        grid-column: 1 / span 2;
    }

    &-Wrapper {
        padding-block: 0;

        @include desktop {
            margin-inline: calc(var(--product-page-wrapper-margin) + var(--max-gallery-thumbnail-height) + 10px);
            margin-block-end: 13rem;
        }

        @include ultra-narrow-desktop {
            margin-inline: var(--product-page-wrapper-margin);
        }

        @include tablet {
            margin-inline: var(--product-page-wrapper-margin);
        }

        @include mobile {
            padding-inline: 0;
            margin-block-end: 55px;

            .slick-list {
                width: calc(100% + 10px);
            }
        }
    }

    &-Title {
        display: block;
        text-align: start;
        text-transform: none;
        font-size: var(--h1-font-size);
        font-weight: bold;
        letter-spacing: -0.08rem;
        margin-block-start: 10px;
        margin-block-end: 30px;
        border-bottom: none;

        @include desktop {
            padding-block-start: 0;
            padding-block-end: 0;
            padding-inline: 0;
        }

        @include narrow-desktop {
            font-size: var(--h1-font-size-narrow);
            line-height: var(--h1-line-height-narrow);
        }

        @include ultra-narrow-desktop {
            font-size: var(--h1-font-size-narrow);
            line-height: var(--h1-line-height-narrow);
        }

        @include tablet {
            font-size: var(--h1-font-size-tablet);
        }

        @include mobile {
            margin-block-start: 50px;
            margin-block-end: 17px;
            padding-block-start: 0;
            padding-block-end: 0;
            padding-inline: 0;
            font-size: 2.7rem;
        }
    }

    .ProductCard {
        margin-inline-end: 0;
        
        &:last-child {
            margin-block-end: 0;
        }
    }
}
