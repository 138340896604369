/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* Sidea Override */

.ProductComparePage {
    margin-block-start: calc(var(--header-wrapper-height) + 60px);

    @include mobile {
        margin-block-start: 30px;
    }

    .ContentWrapper {
        margin: 0 auto;

        @include desktop-wide-1600 {
            margin-inline-start: 33px;
            margin-inline-end: 33px;
        }

        @include mobile {
            margin-inline-start: 18px;
            margin-inline-end: 0;
            margin-block-end: 30px;
            min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
            padding: 0;
            max-width: 100%;
        }

        h1 {
            color: $default-primary-base-color;
            width: calc(100% - 18px);
            text-align: start;
            letter-spacing: -0.1rem;
        }

        .AccountReturnButton {
            @include mobile {
                margin: 0 auto;
                display: block;
                text-align: center;

                &::after {
                    display: none;
                }
            }
        }
    }
}
