/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --price-color: #{$black};
    --price-with-discount-color: #{$default-primary-base-color};
    --price-discount-color: #{$default-neutral-base-color};
}

.ProductPrice {
    color: var(--price-color);
    font-weight: 600;
    font-size: 2.1rem;
    font-style: normal;
    line-height: 1.4;
    margin-block-end: 0;
    margin-block-start: 0;
    vertical-align: middle;

    @include desktop {
        min-height: auto;
    }

    @include mobile {
        font-size: 18px;
    }

    &-Price {
        text-decoration: none;
        display: inline-flex;
        align-items: baseline;
        flex-wrap: wrap;
    }

    del {
        font-size: inherit;
        font-weight: 400;
        color: #9B9B9B;
        opacity: 1;
        display: inline-block;
        margin-inline-end: 15px;
        line-height: 1.4;

        @include mobile {
            margin-block-start: 0;
        }
    }

    &-DiscountBasePrice {
        display: inline-block;

        @include mobile {
            del { 
                margin-inline-end: 0px;
                font-size: 1.6rem;
            }
        }
    }

    &-HighPrice {
        color: var(--price-color);
        line-height: 18px;

        @include desktop {
            line-height: 14px;
        }
    }

    &-PriceValue {
        margin-block-start: 6px;
        line-height: 24px;

        @include desktop {
            line-height: 28px;
        }

        @include mobile {
            margin-block-start: 4px;
        }
    }

    &_hasDiscount {
        .ProductPrice {
            &-PriceValue {
                color: var(--price-with-discount-color);
            }
        }
    }

    &, &-BundleFrom, &-BundleTo {
        &_hasDiscount {
            .ProductPrice {
                &-PriceBadge {
                    margin-block-start: 5px;
                    margin-block-end: -5px;
                }
            }
        }
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-block-start: 4px;

        @include desktop {
            line-height: 16px;
        }
    }

    &-PriceBadge {
        font-size: 14px;
        font-weight: 400;
        margin-inline-end: 4px;
        white-space: nowrap;

        @include desktop {
            line-height: 20px;
        }
    }
}
