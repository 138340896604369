/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Override Sidea */

:root {
    --product-page-wrapper-gap: 9rem;
    --product-page-wrapper-margin: 8.55%;

    @include desktop-wide-1440 {
        --product-page-wrapper-gap: 7rem;
        --product-page-wrapper-margin: 2.8%;
    }

    @include ultra-narrow-desktop {
        --product-page-wrapper-gap: 7rem;
        --product-page-wrapper-margin: 2.8%;
    }

    @include tablet {
        --product-page-wrapper-gap: 7rem;
        --product-page-wrapper-margin: 2.8%;
    }
}

.ProductPage {
    padding-block-start: 30px;

    @include ultra-narrow-desktop {
        padding-block-start: 0;
    }

    @include tablet {
        padding-block-start: 0;
    }

    @include mobile {
        padding-block-start: 0;
        padding-block-end: 0;
        margin-block-end: 0;
        margin-inline: 18px;
    }

    &-Wrapper {
        display: flex;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-template-rows: min-content min-content;
        padding-block-start: 0;
        justify-content: space-between;

        @include desktop {
            grid-column-gap: var(--product-page-wrapper-gap);
            grid-template-areas: '. actions';
            margin-block-end: 20px;
            margin-inline-start: var(--product-page-wrapper-margin);
            margin-inline-end: var(--product-page-wrapper-margin);
            justify-content: space-between;
        }

        @include mobile {
            flex-direction: column;
            grid-template-columns: 100%;
            padding: 0;
        }

        
        .ProductActions {
            @include desktop {
                grid-area: actions;
                width: 37%;
            }

            @include desktop-wide-1440 {
                width: 43%;
            }
        }

        .Share-Reviews {
            width: 9%;
            display: none;

            @include mobile {
                width: 100%;
                /* display: flex; */
                display: none;
                column-gap: 20px;
                margin-block-start: 30px;
                margin-block-end: 20px;
                justify-content: space-around;

                p {
                    margin-block-end: 0;
                }
            }

            svg,
            .p14 svg {
                margin-inline-end: 6px;
                vertical-align: middle;
            }

            div:first-child {
                margin-block-end: 18px;
                font-weight: 600;

                @include mobile {
                    margin-block-end: 0;
                }
            }
        }

        > .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    .banner {
        &.promo,
        &.collect {
            margin-block-start: 110px;

            @include mobile {
                margin-block-start: 45px;
                margin-inline: 0;
            }
        }
    }
}
