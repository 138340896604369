/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

.ProductListWidget {
    margin-block-start: 0;
    padding-block-end: 0;

    /* attivare per rendere il widget con lo slider prodotti con un prodotto e mezzo visibili
    @include mobile {
        overflow-x: scroll;
        overflow-y: hidden;
        margin-inline-end: -20px;
    } */

    &-Page {
        grid-column-gap: 0;
        
        @include narrow-desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include wide-desktop {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mobile {
            grid-auto-flow: column;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 0;

            .ProductCard {
                width: 266px;
                height: 268px;
                margin-block-end: 0;

                &-LinkInnerWrapper {
                    padding: 13px 18px;
                }

                &-Picture {
                    padding-block-end: 78%;
                }

                &-Content {
                    padding: 5px 0 0;
                    font-size: 12px;
                    flex-direction: row;
                    align-content: flex-end;
                    height: 55px;

                    p {
                        margin-block-end: 0;
                        padding-inline-end: 5px;
                    }
                }

                &-ProductInfo {
                    padding-inline-end: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                &-Name {
                    margin: 0;
                    text-overflow: unset;
                    white-space: normal;
                }

                .ProductPrice {
                    font-size: 12px;
                }
            }
        }
    }

    
    @include mobile {
        margin-inline-end: calc(var(--product-card-padding-inline) * -1);
    }

    .ProductsSliderWidget {
        @include mobile {
            width: calc(100% + (var(--product-card-padding-inline) / 2));

            /* width: 235%; attivare per rendere il widget con lo slider prodotti con un prodotto e mezzo visibili */

            .slick-slide {
                padding-inline-end: 5px;
            }
        }
    }
}

.four-products {
    @include mobile {
        display: none;
    }

    .ProductListWidget {
        margin-block-start: 10px;
        
        &-Page {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &.mobile {
        padding-inline: 0;

        @include desktop {
            display: none;
        }

        @include tablet {
            display: none;
        }

        @include mobile {
            display: block;
        }
    }
}
