/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.NewProducts {
    &-Products {
        display: grid;
        grid-auto-rows: max-content;
        grid-column-gap: 0;

        @include mobile {
            grid-column-gap: 0;
            grid-template-columns: 1fr 1fr;
            border: 0;
        }

        @include tablet {
            --new-products-per-page-count: calc(var(--new-products-per-page-count) - 2);

            grid-template-columns: repeat(4, 1fr);
        }

        @include narrow-desktop {
            --new-products-per-page-count: 4;
        }

        grid-template-columns: repeat(var(--new-products-per-page-count), 1fr);
    }
}

#novita {
    .NewProducts {
        margin-block-start: 40px;
        
        h2 {
            display: none;
        }
    }
}
