/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


/** override Sidea */

.ProductAttributes {
    font-size: 14px;
    line-height: 20px;

    &-Wrapper {
        @include desktop {
            padding-block-end: 32px;
        }
    }

    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        &_isContentExpanded {
            @include mobile {
                padding-block: 0;
            }
        }

        @include desktop {
            columns: 2;
            column-gap: 60px;
        }
    }

    &-AttributeBlock {
        break-inside: avoid;
        overflow: hidden;
        width: 100%;
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Group {
        margin: 0;
        padding-block: 32px 16px;
    }

    &-Attributes {
        display: block;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: flex-start;
        padding-inline-start: 1px;

        @include mobile {
            grid-template-columns: 1fr;
        }
    }

    &-ValueLabel,
    &-AttributeLabel {
        border-block-end: none;
        flex: 0 0 50%;
        overflow: visible;
        padding-block: 2px;
        font-size: 14px;
        line-height: 24px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        gap: 5px;

        @include mobile {
            line-height: 20px;
        }

        &:last-of-type {
            border: none;
        }
    }

    &-ValueLabel {
        display: block;

        img {
            max-height: auto;
            max-width: auto;
            object-fit: cover;
            vertical-align: middle;
        }

        .Image-Content {
            position: inherit;
        }

        .ProductAttribute-Label {
            display: inline-block;

            span {
                display: list-item;
                list-style-type: circle;
                list-style-position: inside;

                &::marker {
                    font-size: 18px;
                    line-height: 0;
                }

                &::after {
                    content: ":";
                }
            }

            &.Details {
                display: block;

                span {
                    text-transform: lowercase;
                    
                    &::first-letter {
                        text-transform: uppercase;
                    }

                    &::after {
                        content: "";
                    }
                }
            }
        }

        .ProductAttributeValue {
            display: inline-block;
            vertical-align: top;
            max-width: 80%;
            margin-inline-start: 5px;
        }
    }

    &-Sku {
        text-transform: uppercase !important;
    }

    &-AttributeLabel {
        font-weight: bold;
    }

    &-Image {
        padding-block-end: 40%;
        mix-blend-mode: multiply;
        margin-inline-end: 120px;
        min-width: 360px;
        background: none;

        @include mobile {
            margin-inline-end: 140px;
            min-width: 420px;
            display: none;
        }
    }
}
